<script>
  import Modal from "@/components/modal.vue";
  import Axios from "@/plugins/axios";
  import API from "@/plugins/axios-method";

  export default {
    name: 'user-form-modal',
    components: {Modal},
    props: {
      title: {
        type: String,
        default: "Créer un Superviseur"
      },
      value: {},
      activeUser: {
        type: Object,
        default(){
          return null
        }
      },
      userRole: {
        type: String,
        default: 'member'
      },
    },
    data(){
      return {
        visible: false,
        firstName: null,
        lastName: null,
        birthDate: null,
        sexArr: [
          {value: 'male', label: 'Homme'},
          {value: 'female', label: 'Femme'},
        ],
        rolesArr: [
          {value: 'ROLE_SUPERVISOR', label: 'Superviseur'},
          {value: 'ROLE_COMMERCIAL', label: 'Commercial'},
          {value: 'ROLE_USER', label: 'Membre'},
        ],
        selectedRole: null,
        sex: null,
        phoneNumber: null,
        email: null,
        referralCode: null,
        id: null,
        isSubmitting: false,
        isSubmitted: false,
      }
    },
    watch: {
      value(newValue) {
        this.visible = newValue
      },
      visible(newValue){
        this.visible = newValue
      },
      activeUser(){
        if (this.activeUser){
          this.id = this.activeUser.id
          this.firstName = this.activeUser.firstname
          this.lastName = this.activeUser.lastname
          this.sex = this.activeUser.sex
          this.birthDate = this.activeUser.birthDate?.split('T')[0]
          this.phoneNumber = this.activeUser.phoneNumber
          this.email = this.activeUser.email
          this.selectedRole = this.activeUser.roles[0]
        }
      }
    },
    methods: {
      onModalClose(){
        this.clearForm()
        this.visible = false
        this.isSubmitted = false
        this.isSubmitting = false
        this.$emit('onModalClose', 'user-form')
      },
      formSubmit(){
        if (this.activeUser){
          this.updateUser()
        }else{
          this.createUser()
        }
      },
      createUser(){
        this.isSubmitted = true

        if (!this.isFormValid) return

        this.isSubmitting = true
        Axios.post('/admin/users', {
          firstname: this.firstName,
          lastname: this.lastName,
          email: this.email,
          birthDate: this.birthDate,
          sex: this.sex,
          phoneNumber: this.phoneNumber,
          userRole: this.userRole,
          referralCode: this.referralCode
        })
            .then(response => {
              if (response.status === 201){
                this.userCreateSuccess(response.data)
              }
            })
            .catch(() => {
              this.isSubmitting = false
            })

      },
      updateUser(){
        this.isSubmitted = true

        this.isSubmitting = true
        API.patch('/users/' + this.id, {
          firstname: this.firstName,
          lastname: this.lastName,
          email: this.email,
          birthDate: this.birthDate,
          sex: this.sex,
          userRole: this.userRole,
        })
            .then(response => {
              if (response.status === 200){
                this.userCreateSuccess(response.data)
              }
            })
            .catch(() => {
              this.isSubmitting = false
            })
      },
      userCreateSuccess(user){
        this.isSubmitting = false
        this.isSubmitted = false
        this.clearForm()
        this.onModalClose()
        this.$emit('userCreated', user)
      },
      clearForm(){
        this.firstName = null
        this.lastName = null
        this.email = null
        this.birthDate = null
        this.sex = null
        this.phoneNumber = null
        this.selectedRole = null
        this.referralCode = null
      },
      convertToUpperCase(){
        this.referralCode = this.referralCode.toUpperCase()
      }
    },
    computed: {
      isUpdate(){
        return !!this.activeUser
      },
      phoneNumberState(){
        const regex = /^(69|68|67|65)\d{7}$/
        return this.isSubmitted ? regex.test(this.phoneNumber) : true
      },
      isFormValid(){
        return this.isSubmitted && this.phoneNumberState
      },
      canShoCommercialCodeInput(){
        return !this.isUpdate || this.userRole === '.'
      }
    }
  }
</script>

<template>
  <Modal :title="title" @closeModal="onModalClose" v-model="visible">
    <div class="">
      <form @submit.prevent="formSubmit">
        <div class="row g-3">
          <div class="col-md-6">
            <div>
              <label for="firstName" class="form-label">Nom</label>
              <input type="text" class="form-control" id="firstName" v-model.trim="lastName" placeholder="Nom">
            </div>
          </div><!--end col-->
          <div class="col-md-6">
            <div>
              <label for="lastName" class="form-label">Prenom</label>
              <input type="text" class="form-control" id="lastName" v-model.trim="firstName" placeholder="Prenom">
            </div>
          </div><!--end col-->
          <div class="col-md-6">
            <label for="birtdate" class="form-label">Date naissance</label>
            <input required type="date" class="form-control" id="birthdate" v-model.trim="birthDate">
          </div>
          <div class="col-md-6">
            <label for="sex" class="form-label">Sexe</label>
            <select v-model="sex" class="form-select mb-3">
              <option disabled selected>Choisir le sex</option>
              <option v-for="(sex, index) in sexArr" :key="index" :value="sex.value">{{ sex.label }}</option>
            </select>
          </div>
          <div class="col-md-6" v-if="!isUpdate">
            <label for="phonenumber" class="form-label">Numéro téléphone</label>
            <input placeholder="690054104" type="tel" :class="{'is-invalid': !phoneNumberState}" class="form-control " id="phonenumber" v-model="phoneNumber">
            <div v-if="!phoneNumberState"  class="invalid-feedback">Le numéro est invalid, il doit posséder 9 chiffres et débuter par 69, 67, 65, 68</div>
          </div>
          <div class="col-md-6">
            <div>
              <label for="emailInput" class="form-label">Email</label>
              <input required type="email" class="form-control" id="emailInput" placeholder="Email" v-model="email">
            </div>
          </div><!--end col-->
          <div class="col-md-6">
            <div  v-if="canShoCommercialCodeInput">
              <label for="codeInput" class="form-label">Code Commercial</label>
              <input type="text" class="form-control" id="codeInput" placeholder="Code commercial" @input="convertToUpperCase" v-model.trim="referralCode">
            </div>
          </div><!--end col-->
          <div class="col-lg-12">
            <div class="hstack gap-2 justify-content-end">
                  <button type="submit" :disabled="isSubmitting" class="btn btn-primary">Enregistrer</button>
            </div>
          </div><!--end col-->
        </div><!--end row-->
      </form>
    </div>
  </Modal>

</template>

<style>

</style>